<template>
	<ZyroModal
		max-width="868px"
		max-height="200px"
		class="publish-error-modal"
		@close-modal="closeModal"
	>
		<h2 class="publish-error-modal__title z-h4">
			{{ $t('builder.publishErrorModal.title') }}
		</h2>
		<p class="publish-error-modal__subtitle z-body-small">
			{{ $t('builder.publishErrorModal.subtitle') }}
		</p>
	</ZyroModal>
</template>

<script>
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';

export default { methods: { ...mapActionsGui({ closeModal: CLOSE_MODAL }) } };
</script>

<style lang="scss" scoped>
.publish-error-modal {
	::v-deep .modal__content {
		display: flex;
		flex-direction: column;
		padding: 50px 66px;
	}

	&__title {
		color: $dark;
		text-align: center;
	}

	&__subtitle {
		max-width: 280px;
		margin: 9px auto 20px;
		color: $grey-600;
		text-align: center;
	}
}
</style>
